/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/
/*MAIN COLOR:#087f5b
GREY COLOR:#343a40 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ------------------------ */
/* GENERAL STYLES */
/* ------------------------ */

.ResumeGen {
  display: none;
}

.container2 {
  color: #343a40;
  font-family: "Inter", sans-serif;
  width: 1400px;
  margin: 0 auto;
}

header,
section {
  margin-bottom: 96px;
}
h2 {
  margin-bottom: 48px;
  font-size: 36px;
  letter-spacing: -0.5px;
}

.grid-3-cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
}
.btn:link,
.btn:visited {
  background-color: #087f5b;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  border-radius: 100px;
}
.btn:hover,
.btn:active {
  background-color: #099268;
}
.btn--big {
  padding: 12px 28px;
  font-size: 16px;
  margin-top: 5px;
}
.btn--small {
  font-size: 14px;
  padding: 6px 12px;
}

img {
  border-radius: 12px;
  height: 450px;
  width: 850px;
}
.imagef {
  margin-top: 65px;
  margin-bottom: 28px;
}
/* ------------------------ */
/* COMPONENT STYLES */
/* ------------------------ */

/* HEADER */
header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 80px;
  margin-top: 64px;
}

.header-text-box {
  align-self: center;
}

h1 {
  margin-bottom: 32px;
  font-size: 44px;
  line-height: 1.1;
  letter-spacing: -1px;
  /*text-shadow: 0px 0px 2px #495057;*/
}

.header-text {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.7;
}

img {
  width: 100%;
}

/* FEATURES */
.feature-icon {
  stroke: #087f5b;
  width: 24px;
  height: 24px;
  margin-bottom: 24px;
}

.features-title {
  margin-bottom: 16px;
  font-size: 20px;
}

.features-text {
  font-size: 18px;
  line-height: 1.7;
}

/* TESTIMONIAL */
.testimonial-section {
  background-color: #087f5b;
  color: #fff;
  padding: 24px;
  border-radius: 12px;
}

.testimonial-box {
  grid-column: 2 / -1;
  align-self: center;
}

.testimonial-box h2 {
  margin-bottom: 24px;
  font-size: 24px;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.7;
}

/* resumeS */
.resume {
  box-shadow: 0px 20px 20px 0px #495057;
  border-radius: 12px;
}

.resume img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.image-box {
  padding: 24px;
}

h3 {
  margin-bottom: 24px;
  font-size: 20px;
}

.resume-details {
  list-style: none;
  margin-bottom: 24px;
}

.resume-details li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.resume-details li:last-child {
  margin-bottom: 0;
}

.resume-icon {
  stroke: #099268;
  width: 15px;
  height: 15px;
}

.resume-price {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.res-img {
  height: 250px;
  width: 350px;
}
footer {
  color: #495057;
  margin-bottom: 48px;
  font-size: 14px;
}

#cv-template {
  display: none;
}
#cv-template2 {
  display: none;
}
#ResumeGen2 {
  display: none;
}
.my-image {
  border: 1px solid blue;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.background {
  background-color: lightgreen;
}
#cv-template {
  background-color: white;
}
#cv-form2 {
  background-color: darkslateblue;
}
#cv-form3 {
  background-color: pink;
}
#cv-form4 {
  background-color: yellowgreen;
}
#ResumeGen3 {
  display: none;
}
#cv-template4 {
  display: none;
}
.background2 {
  color: aqua;
}
.background3 {
  background-color: violet;
}
.ResumeGen4 {
  display: none;
}
#cv-template5 {
  display: none;
}
.icons {
  width: 24px;
  height: 24px;
}
.phone {
  display: flex;
  align-content: center;
  gap: 20px;
}
.phone2 {
  display: flex;
  align-content: center;
  gap: 20px;
}

@import url(https://db.onlinewebfonts.com/c/54f66c2598398f5ef0f2e15b8e004e23?family=Scotch+W01+Modern+Display);
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

.elder {
  font-family: "Scotch W01 Modern Display";
}

/* .elder:hover{
    color: #1E8E3E;
    transition: all 0.15s ease-in;
    cursor: pointer;
} */

.aboutdesc p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #7a7373;
  line-height: 1.5;
  font-weight: 400;
}

.bbb {
  margin-top: -15px;
}

.vv {
  font-family: "Raleway", sans-serif;
}
