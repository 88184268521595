.even-row {
  background-color: #f8f9fa;
}

.odd-row {
  background-color: #e9ecef;
}

.dash {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  font-family: sans-serif;
  color: white;
  background-color: #343a40;
  border-radius: 10px;
  margin: 5px;
}

.total {
  border: 0.5px solid black;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  width: fit-content;
}

.tborder {
  border-radius: 5px;
  padding: 5px;
}

.img {
  width: 800px;
  height: 300px;
  margin-bottom: 20px;
}
